import { createTheme } from "baseui";
import type { Colors, Theme, Typography } from "baseui/theme";
import { merge } from "lodash";
import type { DeepPartial } from "ts-essentials";
import { editOverrides, GadgetColors, monoFontFamily, primitivesDark, primitivesLight } from "./EditTheme";
import { gadgetColorsDark, gadgetColorsLight, gadgetShadows } from "./primitives";
import type { PrimitiveFont } from "./utils";

export const proseFamily = `Helvetica, -apple-system, BlinkMacSystemFont, sans-serif`;
export const SpaciousContentBreakpoint = `(min-width: 1180px)`;
export const SidebarNavBreakpoint = `(min-width: 850px)`;
export const ThreeColumnNavBreakpoint = `(min-width: 1180px)`;
export const WideSidebarNavBreakpoint = `(min-width: 1400px)`;

declare module "baseui/theme" {
  interface Colors {
    codePink: string;
    guidesBlue: string;
    apiGrey: string;
    gellyPurple: string;
  }

  interface Font {
    letterSpacing: string;
  }

  interface Breakpoints {
    mini: number;
    xsmall: number;
    small: number;
    medium: number;
    large: number;
    xlarge: number;
  }

  interface MediaQuery {
    mini: string;
    xsmall: string;
    small: string;
    medium: string;
    large: string;
    xlarge: string;
  }
}

const getColorOverrides = (colors: GadgetColors): Partial<Theme["colors"]> => {
  return {
    ...colors,
    inputTextDisabled: colors.primary400,
    inputFillDisabled: colors.primary50,
    inputFill: colors.neutralMin,
    inputFillActive: colors.neutralMin,
    inputBorder: colors.primary200,

    borderFocus: colors.electricBlue500,
    borderSelected: colors.electricBlue400,
    contentPrimary: colors == gadgetColorsDark ? colors.neutralMax : "#0B0B0F",

    codePink: "#FD2189",
    guidesBlue: "#4242FC",
    apiGrey: "#000000",
    gellyPurple: "#9B50D7",
    contentDevelopment: colors.development,
    contentProduction: colors.purple500,
    contentAccent: colors.electricBlue400,

    buttonPrimaryFill: colors.primary700,
    buttonPrimaryHover: colors.primary600,
    buttonSecondaryFill: colors.neutralMin,
    buttonSecondaryHover: colors.primary50,
    buttonTertiaryHover: colors.alpha0,

    linkText: colors.electricBlue500,
    linkVisited: colors.electricBlue500,
    linkHover: colors.electricBlue600,

    contentLinkText: colors.electricBlue500,
    contentLinkHover: colors.electricBlue600,
    contentLinkActive: colors.electricBlue700,
  };
};

interface PrimitiveTypography {
  primaryFontFamily?: string;
  monoFontFamily?: string;
  typography?: {
    ParagraphXSmall?: PrimitiveFont;
    ParagraphSmall?: PrimitiveFont;
    ParagraphMedium?: PrimitiveFont;
    ParagraphLarge?: PrimitiveFont;
    LabelXSmall?: PrimitiveFont;
    LabelSmall?: PrimitiveFont;
    LabelMedium?: PrimitiveFont;
    LabelLarge?: PrimitiveFont;
    HeadingXSmall?: PrimitiveFont;
    HeadingSmall?: PrimitiveFont;
    HeadingMedium?: PrimitiveFont;
    HeadingLarge?: PrimitiveFont;
    HeadingXLarge?: PrimitiveFont;
    HeadingXXLarge?: PrimitiveFont;
    DisplayXSmall?: PrimitiveFont;
    DisplaySmall?: PrimitiveFont;
    DisplayMedium?: PrimitiveFont;
    DisplayLarge?: PrimitiveFont;
    contentLink?: PrimitiveFont;
  };
}

// Derived from https://github.com/gadget-inc/baseweb/blob/build-1b4286d28/themes/shared/typography.js#L155-L172
const PrimitiveTypographyMap: Record<keyof NonNullable<PrimitiveTypography["typography"]>, string> = {
  ParagraphXSmall: "font100",
  ParagraphSmall: "font200",
  ParagraphMedium: "font300",
  ParagraphLarge: "font400",
  LabelXSmall: "font150",
  LabelSmall: "font250",
  LabelMedium: "font350",
  LabelLarge: "font450",
  HeadingXSmall: "font550",
  HeadingSmall: "font650",
  HeadingMedium: "font750",
  HeadingLarge: "font850",
  HeadingXLarge: "font950",
  HeadingXXLarge: "font1050",
  DisplayXSmall: "font1150",
  DisplaySmall: "font1250",
  DisplayMedium: "font1350",
  DisplayLarge: "font1450",
  contentLink: "contentLink",
};

/**
 * BaseUI has a bunch of redundant typography styles that can be
 * derived from a smaller set of primitives. This helper generates
 * those redundant styles.
 */
const generateTypography = (primitive: PrimitiveTypography): DeepPartial<Typography> =>
  Object.fromEntries(
    Object.entries(PrimitiveTypographyMap).flatMap(([name, fontCode]) => {
      const {
        primaryFont: primitivePrimaryFont = {},
        monoFont: primitiveMonoFont = {},
        ...primitiveBaseFont
      } = primitive.typography ? primitive.typography[name as keyof typeof PrimitiveTypographyMap] ?? {} : {};

      const primaryFont = {
        ...(primitive.primaryFontFamily ? { fontFamily: primitive.primaryFontFamily } : {}),
        ...primitiveBaseFont,
        ...primitivePrimaryFont,
      };

      const monoFont = {
        ...(primitive.monoFontFamily ? { fontFamily: primitive.monoFontFamily } : {}),
        ...primitiveBaseFont,
        ...primitiveMonoFont,
      };

      return [
        [fontCode, primaryFont],
        [name, primaryFont],
        [`Mono${name}`, monoFont],
      ];
    })
  );

const defaultDocsTypography: PrimitiveTypography = {
  primaryFontFamily: proseFamily,
  monoFontFamily: monoFontFamily,
  typography: {
    ParagraphXSmall: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
    },
    ParagraphSmall: {
      fontWeight: "normal",
      primaryFont: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      monoFont: {
        fontSize: "15px",
        lineHeight: "20px",
      },
    },
    ParagraphMedium: {
      fontWeight: "normal",
      primaryFont: {
        /* Docs & Web/Body */
        fontSize: "16px",
        lineHeight: "26px",
      },
      monoFont: {
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "25px",
      },
    },
    LabelXSmall: {
      primaryFont: {
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
      },
      monoFont: {
        /* button, search, etc */
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    LabelSmall: {
      fontWeight: "normal",
      lineHeight: "20px",
      primaryFont: {
        fontSize: "14px",
      },
      monoFont: {
        fontSize: "15px",
      },
    },
    LabelMedium: {
      primaryFont: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
      },
      monoFont: {
        fontWeight: "normal",
        fontSize: "17px",
        lineHeight: "25px",
      },
    },
    HeadingXSmall: {
      /* H4 */
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "140%",
    },
    HeadingSmall: {
      /* H3 */
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
    },
    HeadingMedium: {
      /* SectionHeading - H2 */
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
    HeadingLarge: {
      /* PageHeading - H1 */
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
    },
    HeadingXLarge: {
      /* PageHeading - H1 */
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "32px",
    },
    DisplaySmall: {
      /* Display */
      fontWeight: 700,
      fontSize: "42px",
      lineHeight: "53px",
    },
    contentLink: {
      textDecoration: "none",
    },
  },
};

const docsOverrides: any = {
  name: "docs",
  grid: editOverrides.grid,
  lighting: {
    ...gadgetShadows,
    shadow400: "0 1px 0px hsla(0, 0%, 0%, 0.05)",
    shadow500: "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
    shadow600: "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)",
    shadow700: "0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15)",
  },
  typography: generateTypography(defaultDocsTypography),
  borders: {
    useRoundedCorners: true,
    buttonBorderRadius: "6px",
    inputBorderRadius: "6px",
    popoverBorderRadius: "6px",
    surfaceBorderRadius: "8px",
    tableBorderRadius: "8px",
    radius200: "4px",
    radius300: "8px",
    radius400: "10px",
    radius500: "12px",
    checkboxBorderRadius: "4px",
  },
  colors: getColorOverrides(gadgetColorsLight),
};

const readmeOverrides: any = {
  ...docsOverrides,
  name: "readme",
  typography: generateTypography({
    ...defaultDocsTypography,
    primaryFontFamily: "Inconsolata",
  }),
};

const breakpoints: any = {
  mini: 320,
  xsmall: 800,
  small: 1000,
  medium: 1100,
  large: 1440,
  xlarge: 1790,
};

const responsiveCustomTheme = Object.keys(breakpoints).reduce(
  (acc: any, key) => {
    acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    breakpoints,
    mediaQuery: {},
  }
);
docsOverrides.mediaQuery = responsiveCustomTheme.mediaQuery;
docsOverrides.breakpoints = responsiveCustomTheme.breakpoints;

const docsDarkOverrides: any = {
  ...docsOverrides,
  colors: getColorOverrides(gadgetColorsDark),
};

export const DocsLightTheme = createTheme(
  primitivesLight,
  docsOverrides as Theme // don't change the returned type
);

export const DocsDarkTheme = createTheme(primitivesDark, docsDarkOverrides as Theme);

if (typeof window != "undefined") {
  (window as any).DocsLightTheme = DocsLightTheme;
  (window as any).DocsDarkTheme = DocsDarkTheme;
}

export const DocsNarrowLightTheme = createTheme(
  primitivesLight,
  merge({}, docsOverrides, {
    name: "docs-narrow",
    typography: {
      ...docsOverrides.typography,
    },
  }) as Theme // don't change the returned type
);

export const ReadmeTheme = createTheme(
  primitivesLight,
  merge({}, docsOverrides, {
    name: "readme",
    typography: {
      ...readmeOverrides.typography,
    },
  }) as Theme // don't change the returned type
);

export const AreaColors: Record<string, keyof Colors> = {
  code: "codePink",
  gelly: "gellyPurple",
  guides: "guidesBlue",
  api: "apiGrey",
};
