import { flatten } from "lodash";

export enum WorkspacePageType {
  Home = "Home",
  GlobalActions = "GlobalActions",
  DataEdit = "DataEdit",
  Code = "Code",
  ApiKeys = "ApiKeys",
  Logs = "Logs",
  PluginsIndex = "PluginsIndex",
  ShopifyConnectionsIndex = "ShopifyConnectionsIndex",
  ShopifyConnectionsAPIUpgrade = "ShopifyConnectionsAPIUpgrade",
  ShopifyInstalls = "ShopifyInstalls",
  ConnectionInstalls = "ConnectionInstalls",
  ShopifyShopSyncHistory = "ShopifyShopSyncHistory",
  EditShopifyAppCredentials = "EditShopifyAppCredentials",
  AddShopifyAppCredentials = "AddShopifyAppCredentials",
  PermissionsIndex = "PermissionsIndex",
  ConfigurationVariablesIndex = "ConfigurationVariablesIndex",
  ServerOperationLog = "ServerOperationLog",
  BackgroundActionsIndex = "BackgroundActionsIndex",
  BackgroundActionHistory = "BackgroundActionHistory",
  Error = "Error",
  Test = "Test",
  Typecheck = "Typecheck",
  Model = "Model",
  AppSettingIndex = "AppSettingIndex",
  GoogleOauthSettingsIndex = "GoogleOauthSettingsIndex",
  AuthSettingsIndex = "AuthSettingsIndex",
  FrameworkVersionChange = "FrameworkVersionChange",
  OpenAIConnectionIndex = "OpenAIConnectionIndex",
  SentryConnectionIndex = "SentryConnectionIndex",
  BigCommerceConnectionIndex = "BigCommerceConnectionIndex",
  EditBigCommerceAppCredentials = "EditBigCommerceAppCredentials",
}

export const SettingsPageTypes = [
  WorkspacePageType.PluginsIndex,
  WorkspacePageType.ShopifyConnectionsIndex,
  WorkspacePageType.ShopifyConnectionsAPIUpgrade,
  WorkspacePageType.ShopifyInstalls,
  WorkspacePageType.EditShopifyAppCredentials,
  WorkspacePageType.AddShopifyAppCredentials,
  WorkspacePageType.ConfigurationVariablesIndex,
  WorkspacePageType.AppSettingIndex,
  WorkspacePageType.GoogleOauthSettingsIndex,
  WorkspacePageType.AuthSettingsIndex,
  WorkspacePageType.FrameworkVersionChange,
  WorkspacePageType.OpenAIConnectionIndex,
  WorkspacePageType.SentryConnectionIndex,
  WorkspacePageType.BigCommerceConnectionIndex,
  WorkspacePageType.EditBigCommerceAppCredentials,
];

export const SelectableEnvironmentPageTypes = [WorkspacePageType.Logs, WorkspacePageType.DataEdit];

export const DataModelConfigPageTypes = [WorkspacePageType.Model, WorkspacePageType.DataEdit];

export const pageTypesByConnectionIndexPageType: { [key in WorkspacePageType]?: WorkspacePageType[] } = {
  [WorkspacePageType.ShopifyConnectionsIndex]: [
    WorkspacePageType.ShopifyInstalls,
    WorkspacePageType.ShopifyShopSyncHistory,
    WorkspacePageType.ShopifyConnectionsAPIUpgrade,
  ],
};

export const ConnectionPageTypes = [WorkspacePageType.PluginsIndex]
  .concat(Object.keys(pageTypesByConnectionIndexPageType) as WorkspacePageType[])
  .concat(flatten(Object.values(pageTypesByConnectionIndexPageType)));
