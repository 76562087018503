import type { IAnyModelType, Instance, SnapshotIn, SnapshotOrInstance } from "@gadgetinc/mobx-quick-tree";
import { ClassModel, action, register, types } from "@gadgetinc/mobx-quick-tree";
import { RichString } from "@gadgetinc/widgets";
import { maxBy } from "lodash";
import { WorkspacePageType } from "../WorkspacePageType";
import { getDataTypeDisplayName } from "../utils";
import { ProblemSeverity, numericSeverity } from "./ProblemSeverity";

export type ProblemsWithReferenceNode = Pick<Instance<typeof ProblemGroup>, "messages" | "subNodeKey" | "nodeLabels"> & {
  node: Instance<IAnyModelType>;
};

export type ProblemIdentifier = {
  type: string;
  identifier: string;
};

@register
export class SourceLocation extends ClassModel({
  filePath: types.string,
  language: types.string,
  lineNumber: types.number,
  startColumn: types.number,
  startLineNumber: types.number,
  endColumn: types.number,
  endLineNumber: types.number,
}) {}

@register
export class ProblemMessage extends ClassModel({
  severity: types.optional(types.enumeration<ProblemSeverity>(Object.values(ProblemSeverity)), ProblemSeverity.Error),
  message: types.string,
  // only specified if the editor needs to display a different message when the node is in context (visible) and maybe doesn't need to be as long or as detailed
  inContextMessage: types.maybe(types.string),
  name: types.string,
  tabType: types.enumeration<WorkspacePageType>(Object.values(WorkspacePageType)),
  sourceLocation: types.maybe(SourceLocation),
  nodeLabels: types.maybe(types.frozen<ProblemIdentifier[]>()),
  source: types.maybe(types.maybeNull(types.string)),
}) {
  /**
   * Generate a `ProblemMessage` object for one error string without any context
   * Real `ProblemFinder` objects should be much preferred to this thing
   **/
  static quickString(message: string, attrs?: Partial<SnapshotIn<typeof ProblemMessage>>) {
    return this.createReadOnly({
      name: "",
      severity: ProblemSeverity.Error,
      tabType: WorkspacePageType.Home,
      message,
      ...attrs,
    });
  }
}

@register
export class ProblemGroup extends ClassModel({
  property: types.identifier,
  messages: types.array(ProblemMessage),
  subNodeKey: types.maybe(types.string),
  tagName: types.maybe(types.string),
  nodeLabels: types.maybe(types.frozen<ProblemIdentifier[]>()),
}) {
  getDisplayIdentifier(node: Instance<IAnyModelType>): string {
    return node.type === "DataModel"
      ? (this.subNodeKey || this.tagName || node.apiIdentifier) ?? ""
      : (this.tagName || node.apiIdentifier || this.subNodeKey) ?? "";
  }

  getNodeLabels(node: Instance<IAnyModelType>) {
    return (
      this.nodeLabels ?? [
        {
          type: getDataTypeDisplayName(node.type),
          identifier: this.getDisplayIdentifier(node),
        },
      ]
    );
  }
}

/**
 * Represents a list of issues Gadget has identified for a given node. Each problem is a `ProblemMessage` object, which holds a `message` at a given `severity` concerning a specific `property` of the node. We group them into `ProblemGroup` objects per property in the `.problems` map on this object.
 */
@register
export class ProblemsList extends ClassModel({
  groups: types.map(ProblemGroup),
}) {
  highestSeverity(property: string): ProblemSeverity | undefined {
    const problem = this.groups.get(property);
    if (problem) {
      return maxBy(problem.messages, (message) => numericSeverity(message.severity))?.severity;
    }
  }

  hasSeverity(severity: ProblemSeverity): boolean {
    return this.allProblemMessages.some((message) => message.severity == severity);
  }

  withSeverity(severity: ProblemSeverity) {
    return this.allProblemMessages.filter((message) => message.severity == severity);
  }

  problemsOn(property: string, severities?: ProblemSeverity[]): Instance<typeof ProblemMessage>[] {
    const problem = this.groups.get(property);
    if (problem) {
      return problem.messages.filter(({ severity }) => !severities || severities.includes(severity));
    }
    return [];
  }

  get empty() {
    return this.allProblemMessages.length == 0;
  }

  get allProblemMessages() {
    return Array.from(this.groups.values()).flatMap((group) => group.messages);
  }

  get allProblemMessagesAsStrings() {
    return Array.from(this.groups.values()).flatMap((group) =>
      group.messages.map((message) =>
        RichString.parseToString(
          message.inContextMessage ? `${group.property} ${message.inContextMessage}` : `${group.property}: ${message.message}`
        )
      )
    );
  }

  @action
  replace(problems: SnapshotOrInstance<typeof ProblemsList>) {
    this.groups.replace(problems.groups);
  }

  anyProblemsOn(property: string): boolean {
    const messages = this.problemsOn(property);
    if (messages) {
      return messages.length > 0;
    }
    return false;
  }

  allProblemsWithReferenceNode(node: Instance<IAnyModelType>): ProblemsWithReferenceNode[] {
    if (this.empty) return [];

    return Array.from(this.groups.values()).map((problem: Instance<typeof ProblemGroup>) => ({
      messages: problem.messages,
      node,
      subNodeKey: problem.subNodeKey,
      nodeLabels: problem.getNodeLabels(node),
    }));
  }
}

export const EmptyReadOnlyProblemList = ProblemsList.createReadOnly();

export type IgnoreMap<T> = { [key in keyof T]?: T[key][] };

/** Filter a given list of resources to ignore any who  */
export const filterIgnoredResources = <T>(nodes: T[], ignores?: IgnoreMap<T>): T[] => {
  if (!ignores) return nodes;
  return nodes.filter((node) => {
    for (const key in ignores) {
      if (ignores[key]?.includes(node[key])) return false;
    }
    return true;
  });
};
